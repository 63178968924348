export function paperServices() {
    const tabs = document.querySelectorAll('.paper-services__tab');
    const tabContents = document.querySelectorAll('.paper-services__tabs-content');
    tabs.forEach(function (tab) {
        tab.addEventListener('click', function () {
            tabs.forEach(function (t) {
                t.classList.remove('active');
            });
            tabContents.forEach(function (content) {
                content.classList.remove('active');
            });
            tab.classList.add('active');
            const tabId = tab.getAttribute('data-tab');
            const selectedTabContent = document.getElementById(tabId);
            if (selectedTabContent) {
                selectedTabContent.classList.add('active');
            }
        });
    });


}