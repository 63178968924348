import Swiper from "swiper";
export function writerSlider() {

    const writerSwiper = new Swiper('.writer-slider', {
        cssMode: true,
        slidesPerView: 1.1,
        spaceBetween: 12,
        loop: true,
        rewind: true,
        mousewheel: true,
        keyboard: true,
        navigation: {
            nextEl: '.writer-slider-button-next',
            prevEl: '.writer-slider-button-prev',
        },
        pagination: false,
        breakpoints: {
            425: {
                slidesPerView: 1.2,
                spaceBetween: 24,
            },
            744: {
                slidesPerView: 2,
                spaceBetween: 24,
            },
            1200: {
                slidesPerView: 3,
                spaceBetween: 24,
            },
            1280: {
                slidesPerView: 3,
                spaceBetween: 24,
            },
            1440: {
                slidesPerView: 3,
                spaceBetween: 24,
            },
        },
    });
}

