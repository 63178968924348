import navbar from "./components/header/navbar.js";
import {writerSlider} from './components/slider/writerSlider.js'
import {feedbackSlider} from "./components/slider/feedbackSlider.js";
import {paperServices} from "./components/paperServices.js"
import {toggleAccordion} from "./components/toggleAccordion.js";
import Swiper from "swiper";
import {Navigation, Pagination, Thumbs} from "swiper/modules";
import {recentArticlesSlider} from "./components/slider/recentArticlesSlider.js";
import {customSelect} from "./components/customSelect";


Swiper.use([Navigation, Pagination, Thumbs]);

document.addEventListener('DOMContentLoaded', function () {
    navbar()
    writerSlider()
    feedbackSlider()
    paperServices()
    toggleAccordion()
    recentArticlesSlider()
    activeSearch()
    document.querySelectorAll('.custom-select').forEach((select) => {
        customSelect(select);
    })
    switchActiveTab();
    initHorizontalDragScroll();
    toggleTableList();
});

$(document).ready(function () {
    $('#more-reviews').click(function () {
        const page = parseInt($(this).attr('data-page'));
        const form = $('#reviews-filter');
        const formData = new FormData($(form).get(0));
        const action = $(form).attr('action');
        formData.append('page', page);

        $.ajax({
            method: "POST",
            url: action,
            data: formData,
            processData: false,
            contentType: false,
            success: function (resp) {
                $('[data-result-container]').append(resp.items);
                if (resp.hasNextPage) {
                    $('#more-reviews').show().attr('data-page', parseInt(resp.page + 1));
                } else {
                    $('#more-reviews').hide();
                }
            }
        })
    })

    $('#comment-form').submit(function (e) {
        e.preventDefault();

        const form = $(this).get(0);
        const formData = new FormData(form);

        $.ajax({
            method: "POST",
            url: '/ajax/send-review',
            data: formData,
            processData: false,
            contentType: false,
            success: function (resp) {
                $('.tym').addClass('active');
                form.reset();
            }
        })
    })

    $('#support-form').submit(function (e) {
        e.preventDefault();

        const form = $(this).get(0);
        const formData = new FormData(form);

        $.ajax({
            method: "POST",
            url: '/ajax/send-support',
            data: formData,
            processData: false,
            contentType: false,
            success: function (resp) {
                $('.tym').addClass('active');
                form.reset();
            }
        })
    })

    $('.tym .close').click(function () {
        $('.tym').removeClass('active');
    })

    $('#more-comments').click(function () {
        $('.comments__item.hidden').removeClass('hidden');
        $(this).remove();
    })
})

function activeSearch() {
    if (document.getElementById('blog_search')) {
        let searchInput = document.getElementById('blog_search');
        let listGroup = document.querySelector('.search-bar__list-group');

        searchInput.addEventListener('input', function () {
            if (searchInput.value.length >= 3) {
                let s = encodeURI(searchInput.value);

                $.ajax({
                    url: '/ajax/search-post?s=' + s,
                    method: 'GET',
                    success: function (result) {
                        if (result) {
                            listGroup.classList.add('active');
                            $('.search-bar__list').html(result);
                        } else {
                            listGroup.classList.remove('active');
                        }
                    }
                })
            } else {
                listGroup.classList.remove('active');
            }
        });
    }
}

// TODO: What-we-do
function switchActiveTab() {
    const tabs = document.querySelectorAll('.what-we-do__tab');
    const tabContents = document.querySelectorAll('.what-we-do__item');
    const date = document.querySelector('[data-whatWeDo]');

    // * Desktop
    tabs.forEach(tab => {
        tab.addEventListener('click', () => {
            tabs.forEach(t => t.classList.remove('is-active'));
            tabContents.forEach(content => content.classList.remove('is-active'));

            tab.classList.add('is-active');
            const selectedTabContent = document.querySelector(`[data-tabContent='${tab.dataset.tab}']`);

            if (selectedTabContent) {
                selectedTabContent.classList.add('is-active');
            }
        });
    });

    // * Mobile
    customSelect(date, (selectedValue) => {
        console.log('%cwhatWeDo:', 'color: coral; ', selectedValue);
        tabContents.forEach(content => content.classList.remove('is-active'));
        const selectedTabContent = document.querySelector(`[data-tabContent='${selectedValue}']`);

        if (selectedTabContent) {
            selectedTabContent.classList.add('is-active');
        }
    });
}

// TODO: What-we-do
function initHorizontalDragScroll() {
    const slider = document.querySelector('.writer__project--list');
    if (slider) {
        let isDown = false;
        let startX;
        let scrollLeft;
        slider.addEventListener('mousedown', (e) => {
            isDown = true;
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
        });
        slider.addEventListener('mouseleave', () => {
            isDown = false;
        });
        slider.addEventListener('mouseup', () => {
            isDown = false;
        });
        slider.addEventListener('mousemove', (e) => {
            if (!isDown) return;
            e.preventDefault();
            const x = e.pageX - slider.offsetLeft;
            const walk = (x - startX) * 3;
            slider.scrollLeft = scrollLeft - walk;

        });
    }
}

function toggleTableList() {
    const button = document.querySelector('.aside__table-content__mobile-header--button');
    const list = document.querySelector('.mobile-content-list');
    const clickArea = document.querySelector('.aside__table-content__mobile-header');

    if (list) {
        const toggleTableContent = () => {
            list.classList.toggle('is-hidden');
            button.innerText = list.classList.contains('is-hidden') ? 'Show' : 'Hide';
        };

        clickArea.addEventListener('click', toggleTableContent);
    }
}
