export default function navbar() {
    function checkScreenSize() {
        const screenWidth = window.innerWidth;
        if (screenWidth < 1200) {
            toggleMobileDropdown();
            toggleBurgerMenu();
        }
    }

    //TODO: Toggle burger menu
    function toggleBurgerMenu() {
        const refs = {
            openModalButton: document.querySelector('.header__burger-menu'),
            closeModalButton: document.querySelector('.mobile__navbar-mobile__button-close'),
            body: document.body,
            modal: document.querySelector('.navbar'),
        };
        refs.openModalButton.addEventListener('click', function () {
            refs.modal.classList.toggle('show');
            refs.body.classList.toggle('modal-open');
        });

        refs.closeModalButton.addEventListener('click', function () {
            refs.body.classList.toggle('modal-open');
            refs.modal.classList.toggle('show');
        });
    }

    //TODO: Toggle mobile dropdown menu
    function toggleMobileDropdown() {
        const mobileNavBar = document.querySelectorAll('.nav-item__icon');
        mobileNavBar.forEach(item => {
            item.addEventListener('click', function () {
                const parentElement = this.parentElement;
                const anchorElement = parentElement.querySelector('.nav-link');
                const backButton = parentElement.querySelector(
                    '.header-dropdown-mobile__button-back'
                );

                if (parentElement) {
                    parentElement.classList.toggle('show');
                    anchorElement.classList.toggle('show');
                }

                //TODO: backButton
                if (backButton) {
                    backButton.addEventListener('click', function () {
                        parentElement.classList.remove('show');
                        anchorElement.classList.remove('show');
                        item.classList.remove('show');
                    });
                }
                item.classList.toggle('show');
            });
        });
    }

    window.addEventListener('load', checkScreenSize);
    window.addEventListener('resize', checkScreenSize);
}
