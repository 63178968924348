$.fn.sendBinomRequest = function () {
    const data = {
        page: $('[data-bn-page]').attr('data-bn-page'),
        type: $('[data-bn-type]').attr('data-bn-type'),
        link: $(this).attr('data-bn-ln'),
        text: $(this).attr('data-bn-text'),
        site: $('[data-bn-domain]').attr('data-bn-domain'),
        source: readCookie('bn_source'),
        firstPage: readCookie('bn_firstpage')
    }

    jQuery.ajax({
        url: "/ajax/binom/", // Here goes our WordPress AJAX endpoint.
        type: 'post',
        data: data,
        beforeSend: function () {
        },
        success: function (response) {
            console.log(response);
            window.location = response.url
        },
        fail: function (err) {
            console.log("There was an error: " + err);
        }
    });
}

$(document).on('click', '[data-bn-ln]', function (e) {
    e.preventDefault();
    $(this).sendBinomRequest();
})

$(document).ready(function () {
    if(!readCookie('bn_source')){
        createCookie('bn_source', document.referrer, 1)
    }

    if(!readCookie('bn_firstpage')){
        createCookie('bn_firstpage', document.location.href, 1)
    }
})

function createCookie(name, value, days) {
    var expires;

    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
    } else {
        expires = "";
    }
    document.cookie = encodeURIComponent(name) + "=" + encodeURIComponent(value) + expires + "; path=/";
}

function readCookie(name) {
    var nameEQ = encodeURIComponent(name) + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ')
            c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0)
            return decodeURIComponent(c.substring(nameEQ.length, c.length));
    }
    return null;
}

function eraseCookie(name) {
    createCookie(name, "", -1);
}