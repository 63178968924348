import Swiper from "swiper";
export function recentArticlesSlider() {

    const recentArticlesSwiper = new Swiper('.recent-articles-slider', {
        cssMode: true,
        slidesPerView: 1.1,
        spaceBetween: 12,
        loop: false,
        mousewheel: true,
        keyboard: true,
        pagination: false,
        breakpoints: {
            425: {
                slidesPerView: 1.2,
                spaceBetween: 24,
            },
            575: {
                slidesPerView: 1.2,
                spaceBetween: 24,
            },
            744: {
                slidesPerView: 2,
                spaceBetween: 24,
            },
            1200: {
                slidesPerView: 3,
                spaceBetween: 24,
            },

        },
    });
}

