import Swiper from "swiper";

export function feedbackSlider() {

    const feedbackSwiper = new Swiper('.feedback-slider', {
        cssMode: true,
        slidesPerView: 1.1,
        spaceBetween: 12,
        loop: true,
        rewind: true,

        mousewheel: true,
        keyboard: true,
        navigation: {
            nextEl: '.feedback-slider-button-next',
            prevEl: '.feedback-slider-button-prev',
        },
        pagination: false,
        breakpoints: {
            425: {
                slidesPerView: 1.2,
                spaceBetween: 24,
            },
            744: {
                slidesPerView: 2,
                spaceBetween: 24,
            },
            1200: {
                slidesPerView: 2,
                spaceBetween: 24,
            },
            1280: {
                slidesPerView: 2,
                spaceBetween: 24,
            },
            1440: {
                slidesPerView: 2,
                spaceBetween: 24,
            },
        },
    });
}

