//TODO: функция ищет все элементы с классом .custom-select. Если 'element' равняется custom-select то возращает value через функцию onChange

export const customSelect = (select, onChange) => {
    if (select && select.querySelector('.custom-select__active-list')) {
        const selectRef = {
            activeList: select.querySelector('.custom-select__active-list'),
            activeValue: select.querySelector('.custom-select__active-list-value'),
            dropDownList: select.querySelector('.custom-select__drop-down-list'),
            listField: select.querySelector('.custom-select__list-field'),
        };
        // TODO: toggle list show/hide
        const handleBodyClick = () => {
            if (selectRef.dropDownList) {
                if (window.getComputedStyle(selectRef.dropDownList).display === 'block') {
                    select.parentElement.classList.remove('focus');
                }

                document.querySelectorAll('.custom-select .custom-select__drop-down-list').forEach(element => {
                    if (window.getComputedStyle(element).display === 'block') {
                        element.style.display = 'none';
                    }
                });
            }
        };
        // TODO: toggle list show/hide
        const handleActiveListClick = event => {
            event.stopPropagation();
            select.parentElement.classList.toggle('focus');
            selectRef.dropDownList.style.display = window.getComputedStyle(selectRef.dropDownList).display === 'block' ? 'none' : 'block';
        };

        // TODO: select item value
        const handleListItemClick = event => {
            event.stopPropagation();
            const selectedText = event.target.textContent;
            const selectedValue = event.target.getAttribute('data-value');

            select.parentElement.classList.remove('focus');
            select.parentElement.classList.add('added');
            //? Show value to UI
            selectRef.activeValue.textContent = selectedText;
            //? Add value to input
            // selectRef.listField.setAttribute('value', selectedValue);
            // selectRef.listField.dispatchEvent(new Event('input'));
            selectRef.listField.addEventListener('input', () => {
                selectRef.listField.value = selectRef.listField.getAttribute('value');
            });
            selectRef.listField.setAttribute('value', selectedValue);

            //? show drop down list
            selectRef.dropDownList.style.display = window.getComputedStyle(selectRef.dropDownList).display === 'block' ? 'none' : 'block';

            //? Add class active to item
            select.querySelectorAll('.custom-select__drop-down-list li').forEach(item => {
                item.classList.remove('active');
            });
            event.target.classList.add('active');

            const form = $(event.target).closest('form');
            sendData(form);

            //Callback function
            if (select && onChange) {
                onChange(selectedValue);
            }
        };

        document.body.addEventListener('click', handleBodyClick);
        selectRef.activeList.addEventListener('click', handleActiveListClick);
        // TODO: Search dropdown list
        select.querySelectorAll('.custom-select__drop-down-list li').forEach(listItem => {
            listItem.addEventListener('click', handleListItemClick);
        });

        $('#reset-filters').click(function (e) {
            e.stopPropagation();
            e.preventDefault();
            $('.custom-select input').each(function () {
                $(this).get(0).setAttribute('value', '')
            });

            sendData($(this).closest('form'));

            $('.custom-select__active-list-value').each(function () {
                $(this).html($(this).attr('data-placeholder'));
            })

            $('.custom-select__drop-down-list-item').removeClass('active');
            $('.select.added').removeClass('added');
        })

        function sendData(form) {
            const action = $(form).attr('action');
            const formData = new FormData(form[0]);

            $.ajax({
                method: "POST",
                url: action,
                data: formData,
                processData: false,
                contentType: false,
                success: function (resp) {
                    console.log(resp);
                    $('[data-result-container]').html(resp.items);
                    if (resp.hasNextPage) {
                        $('#more-reviews').show().attr('data-page', parseInt(resp.page + 1));
                    } else {
                        $('#more-reviews').hide();
                    }
                }
            })
        }

        function isElementVisible(element) {
            var rect = element.getBoundingClientRect();
            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        }
    }
};


